import React from 'react';

import Header from '../components/Header';
import HomeSection from '../components/HomeSection';
import SiteSection from '../components/SiteSection';
import BannerSection from '../components/BannerSection';
import FooterSection from '../components/FooterSection';


class Index extends React.Component {
    componentDidMount() {
        document.title = "Event Managemtn | Home";
    }

    render() {
        let pKeyword;
        let pCategory;
        let pType;
        let pCity;
        if (this.props.match.params.keyword) {
            pKeyword = this.props.match.params.keyword
        } else {
            pKeyword = ""
        }
        if (this.props.match.params.category) {
            pCategory = this.props.match.params.category
        } else {
            pCategory = ""
        }
        if (this.props.match.params.type) {
            pType = this.props.match.params.type
        } else {
            pType = ""
        }
        if (this.props.match.params.city) {
            pCity = this.props.match.params.city
        } else {
            pCity = ""
        }
        return (
            <div className="site-wrap">
                <Header />
                <HomeSection pKeyword={pKeyword} pCategory={pCategory} pType={pType} pCity={pCity} />
                <SiteSection callAPI="/viewEvent" />
                <BannerSection />
                <FooterSection />
            </div>
        );
    }
}

export default Index;