import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/SaveJobApplicationList.css';
class AllJobApplicationList extends Component {
  constructor() {
    super();
    this.state = {
      jobList: []
    }
    this.userSaveEventList=this.userSaveEventList.bind(this)
  }

  componentDidMount() {
    document.title="Event Portal | Saved Event List"
    this.userSaveEventList()
  }
  unSavedEvent(event_id){
    const req_data = {
      u_id: LoginRow().id,
      e_id: event_id,
      click_btn: ''
    }
    APIUrl.post('/eventBookmark', req_data).then(res => {
      this.userSaveEventList()
    })
  }

  userSaveEventList(){
    const req_m = {
      user_id: LoginRow().id
    }
    APIUrl.post('/userSaveEventList', req_m).then(res => {
      this.setState({ jobList: res.data.result });
    })
  }
  render() {
    let url_job_detail="../../Event/EventView/"
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">

            <div className="content-back">
              <h1>Saved Event List</h1>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Category</th>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Total Seats</th>
                    <th>Price/Head</th>
                    <th>Gender</th>
                    <th>Booking Last Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.jobList.map((jl, index) =>
                    <tr key={jl.save_id}>
                      <td> {index + 1} </td>
                      <td> {jl.category} </td>
                      <td> {jl.type} </td>
                      <td> <a href={url_job_detail+jl.url} >{jl.title}</a> </td>
                      <td> {jl.total_seat} </td>
                      <td> {jl.price_per_head} </td>
                      <td> {jl.gender_type} </td>
                      <td> {jl.end_date} </td>
                      <td> <button onClick={() => this.unSavedEvent(jl.event_id)} className="btn btn-warning">Un Save</button></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default AllJobApplicationList;
