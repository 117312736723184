import React, { Component } from 'react';
import './css/NotFound.css';

class NotFound extends Component {
    componentDidMount() {
        document.title = "Job Portal | Not Found 404";
    }
    render() {
        return (
            <section className="page_404">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-1 "></div>
                        <div className="col-sm-10 ">
                            <div className="col-sm-10   text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>

                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Look like you're lost
                </h3>

                                    <p>the page you are looking for not avaible!</p>

                                    <a href="/" className="link_404">Go to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default NotFound;