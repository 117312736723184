import React, { Component } from 'react';
import APIUrl from '../../APIUrl';
class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      email_l: '',
      password_l: '',
      login_res: '',
    };
    this.loginBtn = this.loginBtn.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyDown=this.handleKeyDown.bind(this)
  }
  loginBtn() {
    if (this.state.email_l && this.state.password_l) {
      if (this.state.password_l.length > 7) {
        const login_reqest = {
          email: this.state.email_l,
          password: this.state.password_l,
          web_type: 2,
          login_btn: ''
        }
        APIUrl.post('/login', login_reqest).then(res => {
          if (res.data.result.login_detail) {
            localStorage.setItem('user_sess', JSON.stringify(res.data.result.login_detail));
            window.location = '/Event';
          } else {
            this.setState({ login_res: 'login failed try again' });
          }
        })
      } else {
        this.setState({ login_res: 'password length min 8 character' });
      }

    } else {
      this.setState({ login_res: 'both field required' });
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyDown(e) {
    if (e.key === 'Enter') {
        
            this.loginBtn()
        
    }
}

  render() {
    if (localStorage.getItem('user_sess')) {
      window.location = '/Event';
    }
    return (
      <div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            {this.state.login_res.length > 0 ? <h5 className="alert alert-danger text-center"> {this.state.login_res} </h5> : ''}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" >Email </label>
            <input type="text" name="email_l" value={this.state.email_l} onKeyDown={this.handleKeyDown} className="form-control" onChange={this.onChange} placeholder="Email Address" />
          </div>
        </div>
        <div className="row form-group mb-4">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black">Password</label>
            <input type="password" name="password_l" value={this.state.password_l} onKeyDown={this.handleKeyDown} className="form-control" onChange={this.onChange} placeholder="Password" />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12">
            <input type="submit" value="Log In"  onClick={this.loginBtn} className="btn px-4 btn-89ba16 text-white" />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;