import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/ChangePassword.css';

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      old_password: '',
      new_password: '',
      con_password: '',
      requestRes: '',
      successRes: ''
    }
    this.changePasswordBtn = this.changePasswordBtn.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount(){
    document.title="Job Portal | Change Password"
  }
  changePasswordBtn() {
    if (this.state.new_password === this.state.con_password) {
      if (this.state.new_password.length > 7) {
        const req1 = {
          id: LoginRow().id,
          old_password: this.state.old_password,
          new_password: this.state.new_password
        }
        APIUrl.post('/changePassword', req1).then(res => {
          if (res.data.result.success) {
            this.setState({ requestRes: '' })
            this.setState({ successRes: 'Password Successfully Changed' })
          } else {
            this.setState({ successRes: '' })
            this.setState({ requestRes: 'Old password is Wrong' })
          }
        })
      } else {
        this.setState({ successRes: '' })
        this.setState({ requestRes: 'password min in 8 character' })
      }

    } else {
      this.setState({ successRes: '' })
      this.setState({ requestRes: 'both password should be same' })
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">

            <div className="content-back">
              <div className="row">
                <div className="col-md-12">
                  <h1>Change Password</h1>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  {this.state.requestRes.length > 0 ? <p className="alert alert-danger text-center"> {this.state.requestRes} </p> : ''}
                  {this.state.successRes.length > 0 ? <p className="alert alert-success text-center"> {this.state.successRes} </p> : ''}
                  <form>
                    <label>Old Password</label>
                    <input type="password" name="old_password" value={this.state.old_password} placeholder="Old Password" className="form-control" onChange={this.onChange} />
                    <label>New Password</label>
                    <input type="password" name="new_password" value={this.state.new_password} placeholder="New Password" className="form-control" onChange={this.onChange} />
                    <label>Confirm Password</label>
                    <input type="password" name="con_password" value={this.state.con_password} placeholder="Confirm Password" className="form-control" onChange={this.onChange} />
                    <br />
                    <button type="button" className="btn btn-info" onClick={this.changePasswordBtn}>Change Password</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default ChangePassword;
