import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/AddAcademic.css';

class AddAcademic extends Component {
  constructor() {
    super();
    this.state = {
      programTitle: '',
      fromDate: '',
      toDate: '',
      instituteName: '',
      fileName: '',
      errorRes: '',
      successRes: ''
    }
    this.onChange = this.onChange.bind(this)
    this.fileOnChange = this.fileOnChange.bind(this)
    this.saveBtn = this.saveBtn.bind(this)
  }
  componentDidMount() {
    document.title = "Job Portal | Add Academic Detail"
  }
  saveBtn() {
    if (this.state.programTitle.length > 0 && this.state.fromDate.length > 0 && this.state.toDate.length > 0 && this.state.instituteName.length > 0 && this.state.fileName.length > 0) {
      const req1 = {
        login_user_id: LoginRow().id,
        program_title: this.state.programTitle,
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        institute_name: this.state.instituteName,
        file_name: this.state.fileName,
        aca_btn: ''
      }
      APIUrl.post('/newAcademic', req1).then(res => {
        if (res.data.result.success) {
          this.setState({ errorRes: '' })
          this.setState({ successRes: 'Successfully Addedd' })
        } else {
          this.setState({ successRes: '' })
          this.setState({ errorRes: res.data.result.failed })
        }
      })
    } else {
      this.setState({ errorRes: 'all field required' })
    }

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  fileOnChange(e) {
    let files = e.target.files
    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = (e) => {
      this.setState({ fileName: e.target.result })
    }
  }
  render() {
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">

            <form>
              <div className="content-back">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Add New Academic</h1>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    {this.state.successRes.length > 0 ? <p className="alert alert-success text-center"> {this.state.successRes} </p> : ''}
                    {this.state.errorRes.length > 0 ? <p className="alert alert-danger text-center"> {this.state.errorRes} </p> : ''}
                    <label>Program Title</label>
                    <input type="text" value={this.state.programTitle} placeholder="Program Title" name="programTitle" className="form-control" onChange={this.onChange} />
                    <label>From Date</label>
                    <input type="date" value={this.state.fromDate} name="fromDate" onChange={this.onChange} className="form-control" />
                    <label>To Date</label>
                    <input type="date" value={this.state.toDate} name="toDate" onChange={this.onChange} className="form-control" />
                    <label>Institute Name</label>
                    <input type="text" value={this.state.instituteName} onChange={this.onChange} name="instituteName" placeholder="Institute Name" className="form-control" />
                    <label>Academic Certificate</label>
                    <input type="file" name="files" onChange={this.fileOnChange} className="form-control" accept=".pdf,image/png,image/jpg,image/jpeg" />
                    <br />
                    <button className="btn btn-info" type="button" onClick={this.saveBtn}>Save Academic</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default AddAcademic;
