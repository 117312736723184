import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/Plan.css';
import Model from 'react-awesome-modal'

class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            plan: [],
            visible: false,
            price: 0,
            plan_id: 0,
            plan_price_id: 0,
            front: true,
            firstNum: 'XXXX',
            secondNum: 'XXXX',
            thirdNum: 'XXXX',
            fourthNum: 'XXXX',
            cvv: 'XXX',
            name: 'CARD HOLDER',
            expDate: 'MM/YY',
            cvvK: '',
            nameK: '',
            expDateK: '',
            cardNum: '',
            message: false,
            confirmBtn: true,
            newVisible: false,
            paymentResp: '',
            user_id: {},
            calculatedPrice: 0,
            fromDate: "",
            toDate: ""
        }
        this.onChange = this.onChange.bind(this);
        this.setStarted = this.setStarted.bind(this)
        this.closeModel = this.closeModel.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.cvvBtn = this.cvvBtn.bind(this)
        this.changeCardNum = this.changeCardNum.bind(this)
        this.sendBtn = this.sendBtn.bind(this)
        this.newCloseModel = this.newCloseModel.bind(this)
    }
    componentDidMount() {
        document.title = "Job Portal | Membership Plan"
        const data = { user_id: LoginRow().od }
        this.setState({ user_id: data })
        APIUrl.get('/ViewPlan').then(res => {
            this.setState({ plan: res.data.result })
        })

    }
    onChange(e) {
        var str = e.target.value
        var fin = str.search("-")
        var tot = str.length
        var plan_price_id = str.substring(0, fin)
        var price = str.substring(fin + 1, tot)
        this.setState({
            ...this.state.plan,
            plan: this.state.plan.map((val) => {
                return val.id === e.target.name ? { ...val, after_choose_price: price, after_choose_plan_price_id: plan_price_id } : val
            })
        })
    }
    setStarted(price, plan_id, plan_price_id) {
        const req_data = {
            user_id: LoginRow().id,
            plan_id: plan_id,
            plan_price_id: plan_price_id
        }
        this.setState({ price: price, plan_id: plan_id, plan_price_id: plan_price_id })
        APIUrl.post('/checkUserPlanDate', req_data).then(ress => {
            if (ress.data.result) {
                this.setState({ visible: true, calculatedPrice: ress.data.result.now_price, fromDate: ress.data.result.from_date, toDate: ress.data.result.to_date })
            } else {
                this.setState({ newVisible: true, paymentResp: 'Please Try again' })
            }
        })

    }
    closeModel() {
        this.setState({ visible: false, confirmBtn: true, message: false, cardNum: '', firstNum: 'XXXX', secondNum: 'XXXX', thirdNum: 'XXXX', fourthNum: 'XXXX', name: 'Card Holder', expDate: 'MM/YY', cvv: '', nameK: '', expDateK: '', cvvK: '' })
    }
    changeValue(e) {
        var nname = e.target.name
        var vvalue = e.target.value
        var len = vvalue.length
        if (nname === "cvv") {

            if (len < 4) {
                this.setState({
                    cvv: vvalue,
                    cvvK: vvalue
                })
            }
        } else if (nname === "expDate") {
            if (len < 6) {
                this.setState({
                    expDate: vvalue,
                    expDateK: vvalue
                })
            }

        } else {
            this.setState({
                name: vvalue,
                nameK: vvalue
            })
        }
    }
    cvvBtn(chk) {
        if (chk === 1) {
            this.setState({ front: true })
        } else {
            this.setState({ front: false })
        }

    }
    changeCardNum(e) {
        var getValue = e.target.value
        var len = getValue.length
        var forr = 16 - parseInt(len)
        var simpleDig = ''
        for (let i = 0; i < forr; i++) {
            simpleDig += 'X'
        }
        var fullNum = getValue + simpleDig
        var firstNumVal = fullNum.substring(0, 4)
        var secondNumVal = fullNum.substring(4, 8)
        var thirdNumVal = fullNum.substring(8, 12)
        var fourthNumVal = fullNum.substring(12, 16)

        if (len <= 16) {
            this.setState({
                cardNum: getValue,
                firstNum: firstNumVal,
                secondNum: secondNumVal,
                thirdNum: thirdNumVal,
                fourthNum: fourthNumVal
            })
        }
    }
    sendBtn(name, cardNum, cvv, expDate) {
        this.setState({ confirmBtn: false })
        if (name.length > 4 && cardNum.length === 16 && cvv.length === 3 && expDate.length === 5) {
            this.setState({ message: false })
            var exp_month = expDate.substring(0, 2)
            var exp_year = expDate.substring(3, 5)
            var ddata = {
                card_number: cardNum,
                exp_month: exp_month,
                exp_year: exp_year,
                card_cvc: cvv,
                amount: this.state.calculatedPrice,
                email: LoginRow().email,
                description: 'testing card',
                name: name,
                phone: '0301',
                plan_id: this.state.plan_id,
                plan_price_id: this.state.plan_price_id,
                user_id: LoginRow().id,
                from_date: this.state.fromDate,
                to_date: this.state.toDate
            }
            this.setState({ cardNum: '', confirmBtn: false, message: false, firstNum: 'XXXX', secondNum: 'XXXX', thirdNum: 'XXXX', fourthNum: 'XXXX', name: 'Card Holder', expDate: 'MM/YY', cvv: '', nameK: '', expDateK: '', cvvK: '' })
            APIUrl.post('/Paymen', ddata).then(res => {
                if (res.data.error) {
                    this.setState({ newVisible: true, paymentResp: res.data.error.message, confirmBtn: true })
                } else {
                    if (res.data.result.status) {
                        this.setState({ newVisible: true, paymentResp: res.data.result.status })
                    } else {
                        this.setState({ newVisible: true, paymentResp: res.data.result.success })
                    }

                }
            })

        } else {
            this.setState({ message: true })
        }
    }
    newCloseModel() {
        this.setState({ newVisible: false, paymentResp: '' })
    }
    render() {
        return (
            <div className="c-app c-default-layout" >
                <TheSidebar />
                <div className="c-wrapper">
                    <TheHeader />
                    <div className="c-body">

                        <div className="content-back">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1>Membership Plan</h1>
                                </div>
                                <Model visible={this.state.visible} width="300px" height="70px" effect="fadeInDown" onClickAway={this.closeModel}>
                                    <div className="model-design">
                                        <div>
                                            <Model visible={this.state.newVisible} width="300px" height="70px" effect="fadeInDown" onClickAway={this.newCloseModel}>
                                                <div className="model-design">
                                                    <h2>{this.state.paymentResp}</h2>

                                                </div>
                                            </Model>
                                            <link rel="stylesheet" href="//code.jquery.com/ui/1.11.4/themes/smoothness/jquery-ui.css" />
                                            <link href='https://fonts.googleapis.com/css?family=Abel' rel='stylesheet' type='text/css' />
                                            <link href='https://fonts.googleapis.com/css?family=Lato:400,700,900' rel='stylesheet' type='text/css' />
                                            <link href='https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff' rel='stylesheet' type='text/css' />
                                            <div className="credit-card">
                                                {this.state.front ?
                                                    <div className="card-front">
                                                        <div className="card-number">
                                                            <span>{this.state.firstNum}</span><span>{this.state.secondNum}</span><span>{this.state.thirdNum}</span><span>{this.state.fourthNum}</span>
                                                        </div>
                                                        <div className="mr-left">
                                                            <div className="exp">
                                                                <span className="label">Good Thru</span>
                                                                <span className="date">{this.state.expDate}</span>
                                                            </div>
                                                            <div className="name-on-card">
                                                                {this.state.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="card-back">
                                                        <div className="magstrip"></div>
                                                        <div className="signature"><span className="sig-name">{this.state.nameK}</span><div className="cvv">{this.state.cvv}</div></div>
                                                        <div className="card-number mr-top">
                                                            <span>{this.state.firstNum}</span><span>{this.state.secondNum}</span><span>{this.state.thirdNum}</span><span>{this.state.fourthNum}</span>
                                                        </div>
                                                        <div className="mr-left">
                                                            <div className="exp mr-top-m">
                                                                <span className="date">{this.state.expDate}</span>
                                                            </div>
                                                            <div className="name-on-card">
                                                                {this.state.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                            <div className="form">
                                                <label>Card Holder Name</label>
                                                <input type="text" className="name" name="name" value={this.state.nameK} onClick={() => this.cvvBtn(1)} onChange={this.changeValue} autoComplete="off" />
                                                <label>Card Number</label>
                                                <input type="password" className="number" name="cardNum" onChange={this.changeCardNum} onClick={() => this.cvvBtn(1)} value={this.state.cardNum} autoComplete="off" />
                                                <div className="column">
                                                    <label>CVV</label>
                                                    <input type="password" className="cvv" name="cvv" onChange={this.changeValue} onClick={() => this.cvvBtn(0)} value={this.state.cvvK} autoComplete="off" />
                                                </div>
                                                <div className="column">
                                                    <label>Exp Date</label>
                                                    <input type="text" name="expDate" onChange={this.changeValue} onClick={() => this.cvvBtn(1)} value={this.state.expDateK} autoComplete="off" />
                                                </div>
                                                {this.state.confirmBtn ?
                                                    <button onClick={() => this.sendBtn(this.state.name, this.state.cardNum, this.state.cvv, this.state.expDate)}>Pay $ {this.state.calculatedPrice}</button> : ''}
                                                {this.state.message ?
                                                    <p className="text-red">Card field required</p> : ''}
                                            </div>
                                        </div>

                                    </div>
                                </Model>
                                {this.state.plan.map(pl =>
                                    <div className="columns" key={pl.id}>
                                        <ul className="price"  >
                                            <li className="header is_popular">{pl.plan_name}</li>
                                            {pl.plan_prices.length > 1 ?
                                                <li className="grey">
                                                    <select className="form-control" onChange={this.onChange} name={pl.id}>
                                                        {pl.plan_prices.map(pl_pr =>
                                                            <option key={pl_pr.id} value={pl_pr.id + "-" + pl_pr.price}>$ {pl_pr.price} / {pl_pr.months}</option>
                                                        )}
                                                    </select>
                                                </li>
                                                : ''}
                                            {pl.plan_prices.length > 1 ?
                                                '' : <li className="grey"><h2 className="text-center text-red">Free</h2>
                                                </li>}
                                            {pl.plan_details.map(pl_d =>
                                                <li key={pl_d.id}>{pl_d.detail}</li>
                                            )}
                                            {pl.plan_prices.length > 1 ?
                                                <li className="grey" ><button className="button" onClick={() => this.setStarted(pl.after_choose_price, pl.id, pl.after_choose_plan_price_id)} value={pl.after_choose_price}>Get Started </button></li> : ''}
                                        </ul>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    <TheFooter />
                </div>
            </div>
        )
    }
}

export default ChangePassword;
