import React, { Component } from 'react';
import APIUrl from '../../APIUrl';
class ContactSection extends Component {
  constructor() {
    super();
    this.state = {
      fname: '',
      lname: '',
      email: '',
      subject: '',
      message: '',
      contact_res: '',
      success_mess: '',
      emailC: '',
      mobileC: '',
      addressC: ''
    }
    this.contactBtn = this.contactBtn.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    APIUrl.get('/contactPage').then(res => {
      if (res.data.result != null) {
        this.setState({
          addressC: res.data.result.address,
          mobileC: res.data.result.mobile,
          emailC: res.data.result.email,
        });
      } else {
        this.setState({
          addressC: '',
          mobileC: '',
          emailC: '',
        });
      }
    })
  }
  contactBtn() {
    if (this.state.fname.length > 2 && this.state.lname.length > 2 && this.state.email.length > 2 && this.state.subject.length > 2 && this.state.message.length > 2) {
      const contact_req = {
        fname: this.state.fname,
        lname: this.state.lname,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
        contact_btn: ''
      }
      APIUrl.post('/contact', contact_req).then(res => {
        this.setState({ contact_res: '' });
        this.setState({ success_mess: 'message successfully sended' });
      })
    } else {
      this.setState({ contact_res: 'all field required with min 3 letters' });
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return (
      <section className="site-section" id="next-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-5 mb-lg-0">

              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  {this.state.contact_res.length > 0 ? <h5 className="alert alert-danger text-center"> {this.state.contact_res} </h5> : ''}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  {this.state.success_mess.length > 0 ? <h5 className="alert alert-success text-center"> {this.state.success_mess} </h5> : ''}
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="text-black" >First Name</label>
                  <input type="text" name="fname" value={this.state.fname} onChange={this.onChange} className="form-control" />
                </div>
                <div className="col-md-6">
                  <label className="text-black" >Last Name</label>
                  <input type="text" name="lname" value={this.state.lname} onChange={this.onChange} className="form-control" />
                </div>
              </div>

              <div className="row form-group">

                <div className="col-md-12">
                  <label className="text-black" >Email</label>
                  <input type="email" name="email" value={this.state.email} onChange={this.onChange} className="form-control" />
                </div>
              </div>

              <div className="row form-group">

                <div className="col-md-12">
                  <label className="text-black" >Subject</label>
                  <input type="subject" name="subject" value={this.state.subject} onChange={this.onChange} className="form-control" />
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <label className="text-black" >Message</label>
                  <textarea name="message" cols="30" rows="7" value={this.state.message} className="form-control" onChange={this.onChange} placeholder="Write your notes or questions here...">{this.state.message}</textarea>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <input type="submit" onClick={this.contactBtn} value="Send Message" className="btn btn-89ba16 btn-md text-white" />
                </div>
              </div>


            </div>
            <div className="col-lg-5 ml-auto">
              <div className="p-4 mb-3 bg-white">
                {this.state.addressC.length > 0 ? <span><p className="mb-0 font-weight-bold">Address</p>
                  <p className="mb-4"> {this.state.addressC} </p></span> : ''}

                {this.state.mobileC.length > 0 ? <span><p className="mb-0 font-weight-bold">Phone</p>
                  <p className="mb-4"><span> {this.state.mobileC} </span></p></span> : ''}

                {this.state.emailC.length > 0 ? <span><p className="mb-0 font-weight-bold">Email Address</p>
                  <p className="mb-0"><span> {this.state.emailC} </span></p></span> : ''}

              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
}
export default ContactSection;