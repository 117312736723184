import React from 'react';
import { Link } from 'react-router-dom';
import './css/ApplicationSection.css';
import img from '../assets/images/apps.png';

function ApplicationSection() {
  return (
    <section className="pt-5 bg-image overlay-primary fixed overlay application-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-self-center text-center text-md-left mb-5 mb-md-0">
            <h2 className="text-white">Get The Mobile Apps</h2>
            <p className="mb-5 lead text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit tempora adipisci impedit.</p>
            <p className="mb-0">
              <Link to="/" className="btn btn-dark btn-md px-4 border-width-2"><span className="icon-apple mr-3"></span>App Store</Link>
              <Link to="/" className="btn btn-dark btn-md px-4 border-width-2"><span className="icon-android mr-3"></span>Play Store</Link>
            </p>
          </div>
          <div className="col-md-6 ml-auto align-self-end">
            <img src={img} alt="Free Website Template by Free-Template.co" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ApplicationSection;