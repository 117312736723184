import React, { Component } from 'react';
import APIUrl from '../../APIUrl';

class RegistrationForm extends Component {
  constructor() {
    super();
    this.state = {
      name_r: '',
      email_r: '',
      new_password_r: '',
      con_password_r: '',
      registration_res: ''
    }
    this.registrationBtn = this.registrationBtn.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }
  registrationBtn() {
    if (this.state.name_r.length > 0 && this.state.email_r.length > 0 && this.state.new_password_r.length > 0 && this.state.con_password_r.length > 0) {
      if (this.state.new_password_r === this.state.con_password_r) {
        if (this.state.new_password_r.length > 7) {
          const registration_req = {
            name: this.state.name_r,
            email: this.state.email_r,
            password: this.state.new_password_r,
            web_type: 2,
            new_btn: ''
          }
          APIUrl.post('/newUser', registration_req).then(res => {
            if (res.data.result.login_detail) {
              localStorage.setItem('user_sess', JSON.stringify(res.data.result.login_detail));
              window.location = '/';
            } else {
              this.setState({ registration_res: 'email already registered' });
            }
          })
        } else {
          this.setState({ registration_res: 'password length min 8 character' });
        }
      } else {
        this.setState({ registration_res: 'both password should be same' });
      }
    } else {
      this.setState({ registration_res: 'all field required' });
    }

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyDown(e) {
    if (e.key === 'Enter') {

      this.registrationBtn()

    }
  }
  render() {
    return (

      <div>

        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            {this.state.registration_res.length > 0 ? <h5 className="alert alert-danger text-center"> {this.state.registration_res} </h5> : ''}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" >Name</label>
            <input type="text" name="name_r" value={this.state.name_r} onChange={this.onChange} onKeyDown={this.handleKeyDown} className="form-control" placeholder="Full Name" />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" >Email</label>
            <input type="text" name="email_r" value={this.state.email_r} onChange={this.onChange} onKeyDown={this.handleKeyDown} className="form-control" placeholder="Email address" />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" >Password</label>
            <input type="password" name="new_password_r" value={this.state.new_password_r} onKeyDown={this.handleKeyDown} onChange={this.onChange} className="form-control" placeholder="Password" />
          </div>
        </div>
        <div className="row form-group mb-4">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" >Re-Type Password</label>
            <input type="password" name="con_password_r" value={this.state.con_password_r} onKeyDown={this.handleKeyDown} onChange={this.onChange} className="form-control" placeholder="Re-type Password" />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12">
            <input type="submit" onClick={this.registrationBtn} value="Sign Up" className="btn px-4 btn-89ba16 text-white" />
          </div>
        </div>

      </div>
    );
  }
}

export default RegistrationForm;