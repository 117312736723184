import {ForPath} from './UrlSetting'
function LoginRow() {
  // let rec={id : "2", f_ame: "Yasir", l_name : "Mehmood", email : "bc140200343@gmail.com"}
  // localStorage.setItem('user_sess',JSON.stringify(rec));
  let login_get = JSON.parse(localStorage.getItem('user_sess'));
  return login_get;
}
var mainURL=ForPath()

function CompanyLogoUrl() {
  let companyLogoUrl = mainURL + '/company_logo/';
  return companyLogoUrl;
}

function JobImgUrl() {
  let jobImgUrl = mainURL + '/job_img/';;
  return jobImgUrl;
}

function EventImgUrl() {
  let eventImgUrl = mainURL + '/event_img/';
  return eventImgUrl;
}

function OurTeamUrl() {
  let ourTeamUrl = mainURL + '/our_team/';
  return ourTeamUrl;
}

function UserAcademicUrl() {
  let userAcademicUrl = mainURL + '/user_academic/';
  return userAcademicUrl;
}

function UserExperienceUrl() {
  let userExperienceUrl = mainURL + '/user_experience/';
  return userExperienceUrl;
}

function UserProfileUrl() {
  let userProfileUrl = mainURL + '/user_profile/';
  return userProfileUrl;
}

export {
  LoginRow,
  CompanyLogoUrl,
  JobImgUrl,
  EventImgUrl,
  OurTeamUrl,
  UserAcademicUrl,
  UserExperienceUrl,
  UserProfileUrl
}