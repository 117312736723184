import React, { Component } from 'react';
import APIUrl from '../../APIUrl';
class FooterSection extends Component {
  state = {
    fCategory: [],
    fCountry: [],
    fbLink: '',
    twLink: '',
    ldLink: '',
    ptLink: ''
  }
  componentDidMount() {
    APIUrl.get('/viewCategory').then(res => {
      this.setState({ fCategory: res.data.result });
    })
    APIUrl.get('/viewCountry').then(res => {
      this.setState({ fCountry: res.data.result });
    })
    APIUrl.get('/contactPage').then(res => {
      if (res.data.result != null) {
        this.setState({
          fbLink: res.data.result.fb_link,
          twLink: res.data.result.tw_link,
          ptLink: res.data.result.pt_link,
          ldLink: res.data.result.ld_link,
        });
      } else {
        this.setState({
          fbLink: '',
          twLink: '',
          ptLink: '',
          ldLink: '',
        });
      }
    })
  }
  render() {
    let cat_url = "/JobCategory/";
    let country_url = "/JobCountry/";
    return (
      <footer className="site-footer">

        <a href="#top" className="smoothscroll scroll-top">
          <span className="icon-keyboard_arrow_up"></span>
        </a>

        <div className="container">
          <div className="row mb-5">
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <h3>Job Category</h3>
              <ul className="list-unstyled">
                {this.state.fCategory.map(cc =>
                  <li key={cc.cat_id}><a href={cat_url + cc.cat_url_name}>{cc.cat_name}</a></li>
                )}
              </ul>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <h3>Job Country</h3>
              <ul className="list-unstyled">
                {this.state.fCountry.map(cc =>
                  <li key={cc.country_id}><a href={country_url + cc.country_url_name}>{cc.country_name}</a></li>
                )}
              </ul>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <h3>Support</h3>
              <ul className="list-unstyled">
                <li><a href="/Policy">Privacy</a></li>
                <li><a href="/TermAndCondition">Terms of Service</a></li>
                <li><a href="/vender/index.php">Login for Add Job</a></li>
              </ul>
            </div>
            <div className="col-6 col-md-3 mb-4 mb-md-0">
              <h3>Contact Us</h3>
              <div className="footer-social">
                {this.state.fbLink.length > 0 ? <a href={this.state.fbLink} target="blank"><span className="icon-facebook"></span></a> : ''}
                {this.state.twLink.length > 0 ? <a href={this.state.twLink} target="blank"><span className="icon-twitter"></span></a> : ''}
                {this.state.ptLink.length > 0 ? <a href={this.state.ptLink} target="blank"><span className="icon-pinterest"></span></a> : ''}
                {this.state.ldLink.length > 0 ? <a href={this.state.ldLink} target="blank"><span className="icon-linkedin"></span></a> : ''}
              </div>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-12">
              <p className="copyright"><small>
                Copyright &copy; All rights reserved | Develop by <i className="icon-heart text-danger" aria-hidden="true"></i> by <a href="https://timetechsol.com" target="blank">TimeTechSol</a>
              </small></p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterSection;