import React, { Component } from 'react'
import APIUrl from '../../APIUrl'

class SearchForm extends Component {
    constructor() {
        super();
        this.state = {
            countryList: [],
            typeList: [],
            categoryList: [],
            cityList: [],
            keyword: '',
            category: '',
            type: '',
            city: ''
        }
        this.findJob = this.findJob.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount() {
        APIUrl.get('/viewCountryWebType/2').then(res => {
            this.setState({ countryList: res.data.result });
        });
        APIUrl.get('/viewEventType').then(res => {
            this.setState({ typeList: res.data.result });
        });
        APIUrl.get('/viewEventCategory').then(res => {
            this.setState({ categoryList: res.data.result });
        });
        APIUrl.get('/viewCityWebType/2').then(res => {
            this.setState({ cityList: res.data.result });
        });
        this.setState({ keyword: this.props.pKeyword.replace(/ /g, "-") })
        this.setState({ category: this.props.pCategory })
        this.setState({ type: this.props.pType })
        this.setState({ city: this.props.pCity })

    }
    findJob() {
        // let keywordTitle = this.state.keyword
        var keywordTitle = this.state.keyword.replace(/ /g, "-");
        if (keywordTitle.length < 2) {
            document.getElementById("keyword").focus()
        } else if (this.state.category.length > 0 && this.state.type.length > 0 && this.state.city) {
            window.location = '/Event/EventSearchAll/' + keywordTitle + "/" + this.state.category + "/" + this.state.type + "/" + this.state.city
        } else if (this.state.category.length > 0 && this.state.type.length > 0) {
            window.location = '/Event/EventSearchCategoryType/' + keywordTitle + "/" + this.state.category + "/" + this.state.type
        } else if (this.state.category.length > 0 && this.state.city.length > 0) {
            window.location = '/Event/EventSearchCategoryCity/' + keywordTitle + "/" + this.state.category + "/" + this.state.city
        } else if (this.state.type.length > 0 && this.state.city.length > 0) {
            window.location = '/Event/EventSearchTypeCity/' + keywordTitle + "/" + this.state.type + "/" + this.state.city
        } else if (this.state.category.length > 0) {
            window.location = '/Event/EventSearchCategory/' + keywordTitle + "/" + this.state.category
        } else if (this.state.type.length > 0) {
            window.location = '/Event/EventSearchType/' + keywordTitle + "/" + this.state.type
        } else if (this.state.city.length > 0) {
            window.location = '/Event/EventSearchCity/' + keywordTitle + "/" + this.state.city
        } else if (keywordTitle.length > 0) {
            window.location = '/Event/EventSearchKeyword/' + keywordTitle
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            if (this.state.keyword.length > 1) {
                this.findJob();
            }
        }
    }
    render() {
        return (
            <div>
                <div className="mb-5 text-center">
                    <h1 className="text-white">Search for Events</h1>
                    <p>Find latest or upcoming Events in our website</p>
                </div>
                <div className="row mb-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4 mb-lg-0">
                        <input type="text" onChange={this.onChange} onKeyDown={this.handleKeyDown} id="keyword" name="keyword" value={this.state.keyword.replace(/-/g, " ")} className="form-control form-control-lg" placeholder="Event title ..." />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4 mt-4 mb-lg-0">
                        <select className="form-control" name="category" id="category" onChange={this.onChange}>
                            {this.state.category.length > 0 ? <option value={this.state.category}>{this.state.category}</option> : <option value="">Choose Category</option>}
                            {this.state.categoryList.map(cList => <option key={cList.cat_id} value={cList.cat_url_name}>{cList.cat_name}</option>)}
                            <option key="0" value="">None</option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4 mt-4 mb-lg-0">
                        <select className="form-control" name="type" id="type" onChange={this.onChange}  >
                            {this.state.type.length > 0 ? <option value={this.state.type}>{this.state.type}</option> : <option value="">Choose Type</option>}
                            {this.state.typeList.map(cList => <option key={cList.type_id} value={cList.type_url_name}>{cList.type_name}</option>)}
                            <option key="0" value="">None</option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4 mt-4 mb-lg-0">
                        <select className="form-control" name="city" id="city" onChange={this.onChange}  >
                            {this.state.city.length > 0 ? <option value={this.state.city}>{this.state.city}</option> : <option value="">Choose City</option>}
                            {this.state.cityList.map(cList => <option key={cList.city_id} value={cList.city_url_name}>{cList.city_name}</option>)}
                            <option key="0" value="">None</option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4 mt-4 mb-lg-0">
                        <button type="submit" onClick={this.findJob} className="btn btn-89ba16  btn-block text-white btn-search"><span className="icon-search icon mr-2"></span>Find Job  </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchForm;
