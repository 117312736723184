import React from 'react';

import Header from '../components/Header';
import SiteSection from '../components/SiteSection';
import FooterSection from '../components/FooterSection';
import Home2Section from '../components/Home2Section';

class JobCountry extends React.Component {
    componentDidMount() {
        document.title = "Job Country - " + this.props.match.params.country;
    }
    render() {
        let calling_api = "/viewJobCountry/" + this.props.match.params.country;
        return (
            <div className="site-wrap">
                <Header />
                <Home2Section page_name={this.props.match.params.country} detail_name="Country Wise Jobs" />
                <SiteSection callAPI={calling_api} />
                <FooterSection />
            </div>
        );
    }
}

export default JobCountry;