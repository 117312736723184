import React from 'react';

import Header from '../components/Header';
import FooterSection from '../components/FooterSection';
import EventDetailBanner from '../components/EventDetailBanner';
import APIUrl from '../../APIUrl';
import JobCountry from '../components/JobCountry';

import SJovView from '../skeleton/SJobView';
import { CompanyLogoUrl, EventImgUrl } from '../../AllProgramming';
import './css/JobView.css'


class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      eventId: 0,
      eventTitle: "Loading...",
      eventDetail: "Loading...",
      companyLogo: "Loading...",
      eventImgName: "Loading...",
      typeName: "Loading...",
      genderType: "Loading...",
      eventStartDate: "Loading...",
      eventEndDate: "Loading...",
      pricePerHead: "Loading...",
      eventTotalSet: "Loading...",
      eventBookmarkStatus: 'no',
      eventApplicationStatus: 'no',
      fbLink: '',
      twLink: '',
      ptLink: '',
      ldLink: '',
      countryCity: [],
      localStorageCheck: false,
      loading: true
    }
    this.applyBookmark = this.applyBookmark.bind(this);
  }


  componentDidMount() {
    this.setState({ loading: true });
    APIUrl.get('/viewEventDetail/' + this.props.match.params.event_url).then(res => {
      let longeur = res.data.result.length;
      if (longeur > 0) {
        document.title = res.data.result[0].event_title;
        this.setState({
          eventId: res.data.result[0].event_id,
          eventTitle: res.data.result[0].event_title,
          eventDetail: res.data.result[0].event_detail,
          companyLogo: res.data.result[0].company_logo,
          eventImgName: res.data.result[0].img_name,
          typeName: res.data.result[0].type_name,
          genderType: res.data.result[0].gender_type,
          eventStartDate: res.data.result[0].event_start_date,
          eventEndDate: res.data.result[0].event_end_date,
          pricePerHead: res.data.result[0].price_per_head,
          eventTotalSet: res.data.result[0].event_total_set,
          fbLink: res.data.result[0].fb_link,
          twLink: res.data.result[0].tw_link,
          ptLink: res.data.result[0].pt_link,
          ldLink: res.data.result[0].ld_link,
          countryCity: res.data.result[0].country_city.countries
        });
        this.setState({ loading: false });
        if (localStorage.getItem('user_sess')) {
          this.setState({ localStorageCheck: true });
          let login_get = localStorage.getItem('user_sess');
          login_get = JSON.parse(login_get);
          const req_data = {
            u_id: login_get.id,
            e_id: res.data.result[0].event_id,
            click_btn: ''
          }
          APIUrl.post('/eventBookmarkStatus', req_data).then(res => {
            this.setState({ eventBookmarkStatus: res.data.result.success });
          })
        }

      }
    });


  }

  applyBookmark() {
    let login_get = localStorage.getItem('user_sess');
    login_get = JSON.parse(login_get);
    const req_data = {
      u_id: login_get.id,
      e_id: this.state.eventId,
      click_btn: ''
    }
    APIUrl.post('/eventBookmark', req_data).then(res => {
      this.setState({ eventBookmarkStatus: res.data.result.success });
    })
  }
  loginRequire() {
    window.location = '/Event/Login';
  }
  applyEvent(){
    window.location = '/Event/';
  }
  render() {
    let event_img = EventImgUrl() + this.state.eventImgName
    let company_logo = CompanyLogoUrl() + this.state.companyLogo
    let text1 = this.state.eventDetail;
    function createMarkup(text) { return { __html: text1 }; };



    return (
      <div className="site-wrap">
        <Header />
        <EventDetailBanner page_name={this.state.eventTitle} img={this.state.eventImgName} detail_name="Event Detail" />
        {this.state.loading ? <SJovView /> :
          <section className="site-section">
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-lg-8 mb-4 mb-lg-0">
                  <div className="d-flex align-items-center">
                    <div className="border p-2 d-inline-block mr-3 rounded">
                      <img src={company_logo} className="company-logo" alt={this.state.CompanyLogo} />
                    </div>
                    <div>
                      <h4 title={company_logo}>{this.state.eventTitle}</h4>
                      <div>
                        <span className="m-2"><span className="icon-clock-o mr-2"></span><span className="text-89ba16">{this.state.typeName} </span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-6">
                      {this.state.eventBookmarkStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyBookmark} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Event</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Event</button>
                        :
                        <button onClick={this.applyBookmark} className="btn btn-block btn-dark btn-md"><span className="icon-heart mr-2 text-danger"></span>Saved Event</button>
                      }
                    </div>
                    <div className="col-6">
                      {this.state.eventApplicationStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyEvent} className="btn btn-block btn-89ba16 btn-md">Booking Now</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-89ba16 btn-md">Booking Now</button>
                        :
                        <button className="btn btn-block btn-info btn-md">Booked</button>
                      }

                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="mb-5">
                    <figure className="mb-5">
                      <img src={event_img} alt={this.state.eventImgName} className="img-fluid rounded" />
                    </figure>
                    <h3 className="h5 d-flex align-items-center mb-4 text-89ba16"><span className="icon-align-left mr-3"></span>Event Description</h3>
                    <div dangerouslySetInnerHTML={createMarkup()} />

                  </div>

                  <div className="row mb-5">
                    <div className="col-6">
                      {this.state.eventBookmarkStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyBookmark} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Event</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Event</button>
                        :
                        <button className="btn btn-block btn-dark btn-md"><span className="icon-heart mr-2 text-danger"></span>Saved Event</button>
                      }
                    </div>
                    <div className="col-6">
                      {this.state.eventApplicationStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyEvent} className="btn btn-block btn-89ba16 btn-md">Booking Now</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-89ba16 btn-md">Booking Now</button>
                        :
                        <button className="btn btn-block btn-info btn-md">Applied</button>
                      }
                    </div>
                    
                  </div>

                </div>
                <div className="col-lg-4">

                  <div className="bg-light p-3 border rounded mb-4">
                    <h3 className="text-89ba16  mt-3 h5 pl-3 mb-3 ">Event Summary</h3>
                    <ul className="list-unstyled pl-3 mb-0">
                      <li className="mb-2 text-primary"><strong className="text-black">Start on:</strong> {this.state.eventStartDate}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Total Seats:</strong> {this.state.eventTotalSet}</li>
                      {/* <li className="mb-2"><strong className="text-black">Experience:</strong> 2 to 3 year(s)</li> */}
                      {/* <li className="mb-2"><strong className="text-black">Job Location:</strong> New ork City</li> */}
                      <li className="mb-2 text-primary"><strong className="text-black">Price Per Head:</strong> {this.state.pricePerHead}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Gender:</strong> {this.state.genderType}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Booking Deadline:</strong> {this.state.eventEndDate}</li>
                      <JobCountry country={this.state.countryCity} />
                    </ul>
                  </div>

                  <div className="bg-light p-3 border rounded">
                    <h3 className="text-89ba16  mt-3 h5 pl-3 mb-3 ">Company Social Links </h3>
                    <div className="px-3">
                      {this.state.fbLink.length > 0 ?
                        <a href={this.state.fbLink} target="blank" className="pt-3 pb-3 pr-3 pl-0"><span className="icon-facebook"></span></a>
                        : ''}
                      {this.state.twLink.length > 0 ?
                        <a href={this.state.twLink} className="pt-3 pb-3 pr-3 pl-0" target="blank"><span className="icon-twitter"></span></a>
                        : ''}

                      {this.state.ldLink.length > 0 ?
                        <a href={this.state.ldLink} className="pt-3 pb-3 pr-3 pl-0" target="blank"><span className="icon-linkedin"></span></a>
                        : ''}

                      {this.state.ptLink.length > 0 ?
                        <a href={this.state.ptLink} className="pt-3 pb-3 pr-3 pl-0" target="blank"><span className="icon-pinterest"></span></a>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
        <FooterSection />
      </div>
    );
  }
}

export default Index;