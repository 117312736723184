import React from 'react'
import { CompanyLogoUrl } from '../../AllProgramming'
import './css/jobList.css'

function JobList(props) {
  let get_company_logo = CompanyLogoUrl() + props.company_logo
  let class_name = "badge badge-" + props.class_name
  let job_url = "/JobView/" + props.url
  return (
    <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center">
      <a href={job_url}> </a>
      <div className="job-listing-logo">
        <img src={get_company_logo} alt="Company Logo" className="job-logo" />
      </div>

      <div className="job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4">
        <div className="job-listing-position custom-width w-50 mb-3 mb-sm-0">
          <h2>{props.title}</h2>
          <strong>{props.category}</strong>
        </div>
        <div className="job-listing-location mb-3 mb-sm-0 custom-width w-25">
          <span className="icon-room"></span> {props.country.map(ct => <span key={ct.country_name}>{ct.country_name}, </span>)}
        </div>
        <div className="job-listing-meta">
          <span className={class_name}>{props.type}</span>
        </div>
      </div>
    </li>
  );
}

export default JobList;