import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

class Index extends Component {
    render() {
        const loop=[]
        for (let i = 0; i < 30; i++) {
            loop.push(i)
        }
        return (
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <br />
                            <h1><Skeleton height={30} /></h1>
                        </div>
                    </div>
                    <duv className="row">
                        {loop.map(i => (
                        <duv className="col-md-4" key={i}>
                            <Skeleton height={300}  />
                        </duv>
                        ))}                        

                    </duv>
                    
                    <div className="row">
                        <div className="col-md-4">
                            <br />
                            <h1><Skeleton height={30} /></h1>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <br />
                            <h1><Skeleton height={30} width={30} circle={true} count={8} /></h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;