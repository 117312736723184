import React, { Component } from 'react';
import { LoginRow, UserExperienceUrl } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/ViewExperience.css';


class ViewExperience extends Component {
  constructor() {
    super();
    this.state = {
      experienceList: [],
      deleteRes: ''
    }
    this.fetchRecord = this.fetchRecord.bind(this);
    this.deleteBtn = this.deleteBtn.bind(this);
  }
  componentDidMount() {
    document.title="Job Portal | View Experience Detail"
    this.fetchRecord();
  }
  fetchRecord() {
    const req_m = {
      user_id: LoginRow().id
    }
    APIUrl.post('/viewUserExperience', req_m).then(res => {
      this.setState({ experienceList: res.data.result });
    })
  }
  deleteBtn(id) {
    const req_m = {
      user_id: LoginRow().id,
      id: id
    }
    APIUrl.post('/deleteUserExperience', req_m).then(res => {
      this.fetchRecord();
      this.setState({ deleteRes: res.data.result });
    })


  }
  render() {
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">

            <div className="content-back">
              <h1> Experience List </h1>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Position Title</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Company Name</th>
                    <th>Job Level</th>
                    <th>Certificate</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.experienceList.map((jl, index) =>
                    <tr key={jl.id}>
                      <td> {index + 1} </td>
                      <td> {jl.position_title} </td>
                      <td> {jl.from_date} </td>
                      <td> {jl.to_date} </td>
                      <td> {jl.job_level} </td>
                      <td> {jl.company_name} </td>
                      <td> <a href={UserExperienceUrl() + jl.certificate} className="btn btn-success" download>Download</a> </td>
                      <td> <span onClick={() => this.deleteBtn(jl.id)} className="btn btn-danger" >Delete</span> </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default ViewExperience;
