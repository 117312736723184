import React from 'react';
import { Component } from 'react';
import APIUrl from '../../APIUrl';
import Model from 'react-awesome-modal'
import './css/BannerSection.css';
class BannerSection extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      message: "",
      visible: false
    }
    this.onChange = this.onChange.bind(this)
    this.saveEmail = this.saveEmail.bind(this)
    this.closeModel = this.closeModel.bind(this)
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  saveEmail() {
    if (this.state.email.length > 10) {
      var data = {
        email: this.state.email,
        subsc_btn: ""
      }
      APIUrl.post('/EmailSubsc', data).then(res => {
        this.setState({ message: res.data.result, visible: true, email: "" })
      })
    } else {
      this.setState({ message: "invalid email format", visible: true })
    }

  }
  closeModel() {
    this.setState({ visible: false })
  }
  render() {
    return (
      <section className="py-5 bg-image overlay-info fixed overlay banner-section">
        <Model visible={this.state.visible} width="300px" height="70px" effect="fadeInDown" onClickAway={this.closeModel} >
          <div className="model-design">
            <br />
            <b>{this.state.message}</b>
          </div>
        </Model>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2 className="text-black">Subscribe for Email alert</h2>
              <p className="mb-0 text-black lead"><input type="email" name="email" value={this.state.email} onChange={this.onChange} placeholder="email@mail.com" className="form-control" required /></p>
            </div>
            <div className="col-md-3 ml-auto">
              <br />
              <br />
              {!localStorage.getItem('user_sess') ? <button onClick={this.saveEmail} className="btn btn-warning btn-block btn-lg">Subscribe</button> : ''}
            </div>
          </div>
        </div>
      </section>
    );
  }

}

export default BannerSection;