import React, { Component } from 'react';
import './css/Home2Section.css';
import SearchForm from './SearchForm';
class Home3Section extends Component {
    render() {
        return (
            <section className="section-hero overlay inner-page bg-image home2-section-e" id="home-section">
                <div className="container">
                    <SearchForm pKeyword={this.props.pKeyword} pCategory={this.props.pCategory} pType={this.props.pType} pCity={this.props.pCity} />
                </div>
            </section>

        );
    }
}
export default Home3Section;