import React from 'react';

import Header from '../components/Header';
import FooterSection from '../components/FooterSection';
import Home2Section from '../components/Home2Section';
import ContactSection from '../components/ContactSection';

class Contact extends React.Component {
    componentDidMount() {
        document.title = "Job Portal | Contact Us";
    }
    render() {
        return (
            <div className="site-wrap">
                <Header />
                <Home2Section page_name="Contact Us" detail_name="Event Management Contact" />
                <ContactSection />
                <FooterSection />
            </div>
        );
    }
}

export default Contact;