import React from 'react';

import Header from '../components/Header';
import FooterSection from '../components/FooterSection';
import Home2Section from '../components/Home2Section';
import APIUrl from '../../APIUrl';
import JobCountry from '../components/JobCountry';

import SJovView from '../skeleton/SJobView';
import { CompanyLogoUrl, JobImgUrl } from '../../AllProgramming';


class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      jobId: 0,
      jobTitle: "Loading...",
      jobDetail: "Loading...",
      companyLogo: "Loading...",
      jobImgName: "Loading...",
      typeName: "Loading...",
      genderType: "Loading...",
      jobStartDate: "Loading...",
      jobEndDate: "Loading...",
      jobSalary: "Loading...",
      jobTotalSet: "Loading...",
      jobBookmarkStatus: 'no',
      jobApplicationStatus: 'no',
      jobApplicationRes: '',
      fbLink: '',
      twLink: '',
      ptLink: '',
      ldLink: '',
      countryCity: [],
      localStorageCheck: false,
      loading: true
    }
    this.applyBookmark = this.applyBookmark.bind(this);
    this.applyJob = this.applyJob.bind(this);
  }


  componentDidMount() {
    this.setState({ loading: true });
    APIUrl.get('/viewJobDetail/' + this.props.match.params.job_url).then(res => {
      let longeur = res.data.result.length;
      if (longeur > 0) {
        document.title = res.data.result[0].job_title;
        this.setState({
          jobId: res.data.result[0].job_id,
          jobTitle: res.data.result[0].job_title,
          jobDetail: res.data.result[0].job_detail,
          companyLogo: res.data.result[0].company_logo,
          jobImgName: res.data.result[0].img_name,
          typeName: res.data.result[0].type_name,
          genderType: res.data.result[0].gender_type,
          jobStartDate: res.data.result[0].job_start_date,
          jobEndDate: res.data.result[0].job_end_date,
          jobSalary: res.data.result[0].job_salary,
          jobTotalSet: res.data.result[0].job_total_set,
          fbLink: res.data.result[0].fb_link,
          twLink: res.data.result[0].tw_link,
          ptLink: res.data.result[0].pt_link,
          ldLink: res.data.result[0].ld_link,
          countryCity: res.data.result[0].country_city.countries
        });
        this.setState({ loading: false });
        if (localStorage.getItem('user_sess')) {
          this.setState({ localStorageCheck: true });
          let login_get = localStorage.getItem('user_sess');
          login_get = JSON.parse(login_get);
          const req_data = {
            u_id: login_get.id,
            j_id: res.data.result[0].job_id,
            click_btn: ''
          }
          APIUrl.post('/jobBookmarkStatus', req_data).then(res => {
            this.setState({ jobBookmarkStatus: res.data.result.success });
          })
          APIUrl.post('/jobApplicationStatus', req_data).then(res => {
            this.setState({ jobApplicationStatus: res.data.result.success });
          })
        }

      }
    });


  }

  applyBookmark() {
    let login_get = localStorage.getItem('user_sess');
    login_get = JSON.parse(login_get);
    const req_data = {
      u_id: login_get.id,
      j_id: this.state.jobId,
      click_btn: ''
    }
    APIUrl.post('/jobBookmark', req_data).then(res => {
      this.setState({ jobBookmarkStatus: res.data.result.success });
    })
  }
  applyJob() {
    let login_get = localStorage.getItem('user_sess');
    login_get = JSON.parse(login_get);
    const req_data = {
      user_id: login_get.id,
      job_id: this.state.jobId
    }
    APIUrl.post('/jobApplication', req_data).then(res => {
      if (res.data.result.success) {
        this.setState({ jobApplicationStatus: res.data.result.success });
      } else {
        this.setState({ jobApplicationRes: res.data.result.failed });
      }

    })
  }
  loginRequire() {
    window.location = '/Login';
  }
  render() {
    let job_img = JobImgUrl() + this.state.jobImgName
    let company_logo = CompanyLogoUrl() + this.state.companyLogo
    let text1 = this.state.jobDetail;
    function createMarkup(text) { return { __html: text1 }; };



    return (
      <div className="site-wrap">
        <Header />
        <Home2Section page_name={this.state.jobTitle} detail_name="Job Detail" />
        {this.state.loading ? <SJovView /> :
          <section className="site-section">
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-lg-8 mb-4 mb-lg-0">
                  <div className="d-flex align-items-center">
                    <div className="border p-2 d-inline-block mr-3 rounded">
                      <img src={company_logo} className="job-logo" alt={this.state.CompanyLogo} />
                    </div>
                    <div>
                      <h4 title={company_logo}>{this.state.jobTitle}</h4>
                      <div>
                        <span className="m-2"><span className="icon-clock-o mr-2"></span><span className="text-89ba16">{this.state.typeName} </span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-6">
                      {this.state.jobBookmarkStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyBookmark} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Job</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Job</button>
                        :
                        <button onClick={this.applyBookmark} className="btn btn-block btn-dark btn-md"><span className="icon-heart mr-2 text-danger"></span>Saved Job</button>
                      }
                    </div>
                    <div className="col-6">
                      {this.state.jobApplicationStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyJob} className="btn btn-block btn-89ba16 btn-md">Apply Now</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-89ba16 btn-md">Apply Now</button>
                        :
                        <button onClick={this.applyJob} className="btn btn-block btn-info btn-md">Applied</button>
                      }

                    </div>
                    <div className="col-12 text-center text-red">{this.state.jobApplicationRes.length > 0 ? <span> {this.state.jobApplicationRes} </span> : ''}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="mb-5">
                    <figure className="mb-5">
                      <img src={job_img} alt={this.state.jobImgName} className="img-fluid rounded" />
                    </figure>
                    <h3 className="h5 d-flex align-items-center mb-4 text-89ba16"><span className="icon-align-left mr-3"></span>Job Description</h3>
                    <div dangerouslySetInnerHTML={createMarkup()} />

                  </div>

                  <div className="row mb-5">
                    <div className="col-6">
                      {this.state.jobBookmarkStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyBookmark} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Job</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-light btn-md"><span className="icon-heart-o mr-2 text-danger"></span>Save Job</button>
                        :
                        <button onClick={this.applyBookmark}  className="btn btn-block btn-dark btn-md"><span className="icon-heart mr-2 text-danger"></span>Saved Job</button>
                      }
                    </div>
                    <div className="col-6">
                      {this.state.jobApplicationStatus === 'no' ?
                        this.state.localStorageCheck === true ?
                          <button onClick={this.applyJob} className="btn btn-block btn-89ba16 btn-md">Apply Now</button>
                          :
                          <button onClick={this.loginRequire} className="btn btn-block btn-89ba16 btn-md">Apply Now</button>
                        :
                        <button onClick={this.applyJob} className="btn btn-block btn-info btn-md">Applied</button>
                      }
                    </div>
                    <div className="col-12 text-center text-red">{this.state.jobApplicationRes.length > 0 ? <span> {this.state.jobApplicationRes} </span> : ''}</div>
                  </div>

                </div>
                <div className="col-lg-4">

                  <div className="bg-light p-3 border rounded mb-4">
                    <h3 className="text-89ba16  mt-3 h5 pl-3 mb-3 ">Job Summary</h3>
                    <ul className="list-unstyled pl-3 mb-0">
                      <li className="mb-2 text-primary"><strong className="text-black">Published on:</strong> {this.state.jobStartDate}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Vacancy:</strong> {this.state.jobTotalSet}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Employment Status:</strong> {this.state.typeName}</li>
                      {/* <li className="mb-2"><strong className="text-black">Experience:</strong> 2 to 3 year(s)</li> */}
                      {/* <li className="mb-2"><strong className="text-black">Job Location:</strong> New ork City</li> */}
                      <li className="mb-2 text-primary"><strong className="text-black">Salary:</strong> {this.state.jobSalary}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Gender:</strong> {this.state.genderType}</li>
                      <li className="mb-2 text-primary"><strong className="text-black">Application Deadline:</strong> {this.state.jobEndDate}</li>
                      <JobCountry country={this.state.countryCity} />
                    </ul>
                  </div>

                  <div className="bg-light p-3 border rounded">
                    <h3 className="text-89ba16  mt-3 h5 pl-3 mb-3 ">Company Social Links </h3>
                    <div className="px-3">
                      {this.state.fbLink.length > 0 ?
                        <a href={this.state.fbLink} target="blank" className="pt-3 pb-3 pr-3 pl-0"><span className="icon-facebook"></span></a>
                        : ''}
                      {this.state.twLink.length > 0 ?
                        <a href={this.state.twLink} className="pt-3 pb-3 pr-3 pl-0" target="blank"><span className="icon-twitter"></span></a>
                        : ''}

                      {this.state.ldLink.length > 0 ?
                        <a href={this.state.ldLink} className="pt-3 pb-3 pr-3 pl-0" target="blank"><span className="icon-linkedin"></span></a>
                        : ''}

                      {this.state.ptLink.length > 0 ?
                        <a href={this.state.ptLink} className="pt-3 pb-3 pr-3 pl-0" target="blank"><span className="icon-pinterest"></span></a>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
        <FooterSection />
      </div>
    );
  }
}

export default Index;