import React from 'react'

import {Index, About, Contact, Login, JobView, JobCategory, JobType, JobCountry, Policy, TermAndCondition, JobSearch, NodeFound } from './jobWeb/pages/exportPages'
import {AddAcademic, AddExperience, AllJobApplicationList, ChangePassword, Profile, StatusJobApplicationList, ViewAcademic, ViewExperience, SaveJobList, Plan, ViewTransaction } from './jobWeb/user/views/index'

import {EIndex, EAbout, EContact, ELogin, EJobView, EJobCategory, EJobType, EJobCountry, EPolicy, ETermAndCondition, EJobSearch } from './eventWeb/pages/exportPages'
import {EChangePassword, EPlan, EProfile,    ESaveJobList, EViewTransaction } from './eventWeb/user/views/index'

import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'

let browserHistory = createBrowserHistory({})
function MainRoutes() {
    let eventWeb="/Event/"
    // let scholarshipWeb="/Scholar/"
    return (
        <BrowserRouter history={browserHistory}>
            <Switch>
                
                <Route path="/" exact component={Index} />
                <Route path="/Login" component={Login} />
                <Route path="/JobView/:job_url" component={JobView} />
                <Route path="/JobCategory/:category" component={JobCategory} />
                <Route path="/JobType/:type" component={JobType} />
                <Route path="/JobCountry/:country" component={JobCountry} />
                <Route path="/JobSearchAll/:keyword/:category/:type/:city" component={JobSearch} />
                <Route path="/JobSearchCategoryType/:keyword/:category/:type" component={JobSearch} />
                <Route path="/JobSearchCategoryCity/:keyword/:category/:city" component={JobSearch} />
                <Route path="/JobSearchTypeCity/:keyword/:type/:city" component={JobSearch} />
                <Route path="/JobSearchCategory/:keyword/:category" component={JobSearch} />
                <Route path="/JobSearchType/:keyword/:type" component={JobSearch} />
                <Route path="/JobSearchCity/:keyword/:city" component={JobSearch} />
                <Route path="/JobSearchKeyword/:keyword" component={JobSearch} />
                <Route path="/TermAndCondition" component={TermAndCondition} />
                <Route path="/Policy" component={Policy} />
                <Route path="/About" component={About} />
                <Route path="/Contact" component={Contact} />                
                <Route path="/User/StatusJobApplicationList/:id"  component={StatusJobApplicationList} />
                <Route path="/User/AddAcademic"  component={AddAcademic} />
                <Route path="/User/AddExperience"  component={AddExperience} />
                <Route path="/User/ViewAcademic"  component={ViewAcademic} />
                <Route path="/User/ViewExperience"  component={ViewExperience} />
                <Route path="/User/ChangePassword"  component={ChangePassword} />
                <Route path="/User/SaveJobList"  component={SaveJobList} />  
                <Route path="/User/Profile"  component={Profile} />                
                <Route path="/User/AllJobApplicationList"  component={AllJobApplicationList} />
                <Route path="/User/Plan"  component={Plan} /> 
                <Route path="/User/ViewTransaction"  component={ViewTransaction} /> 
                <Route path="/User"  component={AllJobApplicationList} /> 

                <Route path={eventWeb} exact component={EIndex} />
                <Route path={eventWeb+"Login"} component={ELogin} />
                <Route path={eventWeb+"EventView/:event_url"} component={EJobView} />
                <Route path={eventWeb+"EventCategory/:category"} component={EJobCategory} />
                <Route path={eventWeb+"EventType/:type"} component={EJobType} />
                <Route path={eventWeb+"EventCountry/:country"} component={EJobCountry} />
                <Route path={eventWeb+"EventSearchAll/:keyword/:category/:type/:city"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchCategoryType/:keyword/:category/:type"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchCategoryCity/:keyword/:category/:city"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchTypeCity/:keyword/:type/:city"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchCategory/:keyword/:category"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchType/:keyword/:type"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchCity/:keyword/:city"} component={EJobSearch} />
                <Route path={eventWeb+"EventSearchKeyword/:keyword"} component={EJobSearch} />
                <Route path={eventWeb+"TermAndCondition"} component={ETermAndCondition} />
                <Route path={eventWeb+"Policy"} component={EPolicy} />
                <Route path={eventWeb+"About"} component={EAbout} />
                <Route path={eventWeb+"Contact"} component={EContact} />                
                <Route path={eventWeb+"User/ChangePassword"}  component={EChangePassword} />
                <Route path={eventWeb+"User/SaveEventList"}  component={ESaveJobList} />  
                <Route path={eventWeb+"User/Profile"}  component={EProfile} />                
                <Route path={eventWeb+"User/Plan"}  component={EPlan} />                
                <Route path={eventWeb+"User/ViewTransaction"}  component={EViewTransaction} />                
                <Route path={eventWeb+"User"}  component={ESaveJobList} /> 

                <Route path="*" component={NodeFound} />
            </Switch>
        </BrowserRouter>
    );
}

export default MainRoutes;