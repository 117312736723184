import React, { Component } from 'react';
import './css/CareerSection.css';
import APIUrl from '../../APIUrl';
class CareerSection extends Component {
  state = {
    totalVender: 0,
    totalUser: 0,
    totalCategory: 0,
    totalActiveJob: 0,
  }

  componentDidMount() {
    APIUrl.get('/totalVender').then(res => {
      this.setState({ totalVender: res.data.result.total_vender });
    });
    APIUrl.get('/totalUser').then(res => {
      this.setState({ totalUser: res.data.result.total_user });
    });
    APIUrl.get('/totalCategory').then(res => {
      this.setState({ totalCategory: res.data.result.total_category });
    });
    APIUrl.get('/totalActiveJob').then(res => {
      this.setState({ totalActiveJob: res.data.result.total_active_job });
    });

  }
  
  render() {
    return (
      <section className="py-5 bg-image overlay-primary fixed overlay career-section" id="next">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-7 text-center">
              <h2 className="section-title mb-2 text-white">Careers Statistics</h2>
              <p className="lead text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita unde officiis recusandae sequi excepturi corrupti.</p>
            </div>
          </div>
          <div className="row pb-0 block__19738 section-counter">

            <div className="col-6 col-md-6 col-lg-3 mb-5 mb-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2">
                <strong className="number" data-number={this.state.totalActiveJob}>{this.state.totalActiveJob}</strong>
              </div>
              <span className="caption">Jobs Posted</span>
            </div>

            <div className="col-6 col-md-6 col-lg-3 mb-5 mb-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2">
                <strong className="number" data-number={this.state.totalUser}>{this.state.totalUser}</strong>
              </div>
              <span className="caption">Candidates</span>
            </div>

            <div className="col-6 col-md-6 col-lg-3 mb-5 mb-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2">
                <strong className="number" data-number={this.state.totalCategory}>{this.state.totalCategory}</strong>
              </div>
              <span className="caption">Jobs Category</span>
            </div>

            <div className="col-6 col-md-6 col-lg-3 mb-5 mb-lg-0">
              <div className="d-flex align-items-center justify-content-center mb-2">
                <strong className="number" data-number={this.state.totalVender}>{this.state.totalVender}</strong>
              </div>
              <span className="caption">Companies</span>
            </div>


          </div>
        </div>
      </section>
    );
  }
}

export default CareerSection;