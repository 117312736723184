import React from 'react';
import './css/BannerSection.css';
function BannerSection() {
  return (
    <section className="py-5 bg-image overlay-info fixed overlay banner-section-e">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h2 className="text-black">Looking For Upcoming Events</h2>
            <p className="mb-0 text-black lead">Lorem ipsum dolor sit amet consectetur adipisicing elit tempora adipisci impedit.</p>
          </div>
          <div className="col-md-3 ml-auto">
            {!localStorage.getItem('user_sess') ? <a href="/Login" className="btn btn-warning btn-block btn-lg">Sign Up</a> : ''}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerSection;