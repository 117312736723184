import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './css/TheSidebar.css';
import APIUrl from '../../../APIUrl';
import logo from '../assets/images/logo.png';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarMinimizer, } from '@coreui/react';

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  const [applicationStatus, setApplicationStatus] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('user_sess')) {
      window.location = '../../'
    }
    const fetchApplicationStatus = async () => {
      const res = await APIUrl.get('/applicationStatus');
      setApplicationStatus(res.data.result);
    };
    fetchApplicationStatus();
  }, []);
  let url_mak = "/User/StatusJobApplicationList/";
  return (
    <CSidebar show={show} onShowChange={(val) => dispatch({ type: 'set', sidebarShow: val })} >
      <CSidebarBrand className="d-md-down-none" to="/"><img src={logo} className="logo" alt="logo" /></CSidebarBrand>
      <CSidebarNav>
        <a href="/User/SaveJobList" className="side-bar-link" >Save Job </a>
        <b className="side-bar-title">Job Application </b>
        <a href="/User/AllJobApplicationList" className="side-bar-link">All Job Application</a>
        {applicationStatus.map(ap => <a href={url_mak + ap.id} className="side-bar-link" key={ap.id}>{ap.name} Job Application</a>)}
        <b className="side-bar-title">Experience </b>
        <a href="/User/AddExperience" className="side-bar-link">Add Experience</a>
        <a href="/User/ViewExperience" className="side-bar-link">View Experience</a>
        <b className="side-bar-title">Academic </b>
        <a href="/User/AddAcademic" className="side-bar-link">Add Academic</a>
        <a href="/User/ViewAcademic" className="side-bar-link">View Academic</a>
        <b className="side-bar-title">Profile </b>
        <a href="/User/Profile" className="side-bar-link">Profile</a>
        <a href="/User/ChangePassword" className="side-bar-link">Change Password</a>
        <a href="/User/Plan" className="side-bar-link">Upgrade Plan</a>
        <a href="/User/ViewTransaction" className="side-bar-link">View Transaction</a>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
