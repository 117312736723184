import React from 'react';

import Header from '../components/Header';
import FooterSection from '../components/FooterSection';
import Home2Section from '../components/Home2Section';
import RegistrationForm from '../components/RegistrationForm';
import LoginForm from '../components/LoginForm';

class Index extends React.Component {

    componentDidMount() {
        document.title = "Job Portal | Account Login";
    }

    render() {
        return (
            <div className="site-wrap">
                <Header />
                <Home2Section page_name="Account Panel" detail_name="SignIn / SignUp" />
                <section className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-5">
                                <h2 className="mb-4">Sign Up To Job Panel</h2>
                                <RegistrationForm />
                            </div>
                            <div className="col-lg-6">
                                <h2 className="mb-4">Log In To Job Panel</h2>
                                <LoginForm />
                            </div>
                        </div>
                    </div>
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default Index;