import React, { Component } from 'react';
import APIUrl from '../../APIUrl';
import './css/Header.css'

class Header extends Component {
    state = {
        countryList: [],
        typeList: [],
        categoryList: [],
        country_url: "/Event/EventCountry/",
        category_url: "/Event/EventCategory/",
        type_url: "/Event/EventType/",
    }
    componentDidMount() {
        APIUrl.get('/viewCountryWebType/2').then(res => {
            this.setState({ countryList: res.data.result });
        });
        APIUrl.get('/viewEventType').then(res => {
            this.setState({ typeList: res.data.result });
        });
        APIUrl.get('/viewEventCategory').then(res => {
            this.setState({ categoryList: res.data.result });
        });
    }
    logout() {
        localStorage.clear();
        window.location = '/Event/Login';
    }

    render() {
        let eventWeb="/Event/"
        return (
            <header className="site-navbar mt-3">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="site-logo col-2"><a href={eventWeb}>Event.Management</a></div>

                        <nav className="mx-auto site-navigation">
                            <ul className="site-menu js-clone-nav d-none d-xl-block ml-0 pl-0">
                            <li className="has-children">
                                    <a href="/Event">Event Management</a>
                                    <ul className="dropdown">
                                        <li><a href="/">Job Portal</a></li>
                                        <li><a href="/Scholar">Scholarship </a></li>
                                        
                                    </ul>
                                </li>
                                <li><a href={eventWeb+"About"}>About</a></li>
                                <li className="has-children">
                                    <a href={eventWeb}>Event Category</a>
                                    <ul className="dropdown">
                                        {this.state.categoryList.map(cL =>
                                            <li key={cL.cat_id}><a href={this.state.category_url + cL.cat_url_name}>{cL.cat_name}</a></li>
                                        )}
                                    </ul>
                                </li>
                                <li className="has-children">
                                    <a href={eventWeb} to="">Event Type</a>
                                    <ul className="dropdown">
                                        {this.state.typeList.map(cL =>
                                            <li key={cL.type_id}><a href={this.state.type_url + cL.type_url_name}>{cL.type_name}</a></li>
                                        )}
                                    </ul>
                                </li>
                                <li className="has-children">
                                    <a href={eventWeb} to="">Country</a>
                                    <ul className="dropdown">
                                        {this.state.countryList.map(cL =>
                                            <li key={cL.country_id}><a href={this.state.country_url + cL.country_url_name}>{cL.country_name}</a></li>
                                        )}
                                    </ul>
                                </li>
                                <li><a href={eventWeb+"Contact"}>Contact</a></li>
                                {localStorage.getItem('user_sess') ?
                                    <li className="d-lg-none"><a href={eventWeb}><span className="mr-2">+</span>Dashboard</a></li>
                                    : ''}
                                <li className="d-lg-none"><a href={eventWeb+"Login"}>Log In</a></li>
                            </ul>
                        </nav>

                        <div className="right-cta-menu text-right d-flex aligin-items-center col-4">
                            <div className="ml-auto">

                                {localStorage.getItem('user_sess') ?
                                    <span> <a href={eventWeb+"User/"} className="btn btn-outline-white border-width-2 d-none d-lg-inline-block"><span className="mr-2 icon-add"></span>Dashboard</a>
                                       <span>&nbsp;&nbsp;&nbsp; <button onClick={this.logout} className="logout-color-red"><span className="mr-2 icon-lock_outline"></span>Logout</button></span> </span>
                                    :
                                    
                                    <span>&nbsp;&nbsp;&nbsp;<a href={eventWeb+"Login"} className="btn btn-89ba16 border-width-2 d-none d-lg-inline-block text-white"><span className="mr-2 icon-lock_outline"></span> Log In</a></span>
                                }
                               
                            </div>
                            <a href={eventWeb} className="site-menu-toggle js-menu-toggle d-inline-block d-xl-none mt-lg-2 ml-3"><span className="icon-menu h3 m-0 p-0 mt-2"></span></a>
                        </div>

                    </div>
                </div>
            </header>
        );
    }
}

export default Header;