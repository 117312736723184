import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/AddExperience.css';

class AddExperience extends Component {
  constructor() {
    super();
    this.state = {
      positionTitle: '',
      fromDate: '',
      toDate: '',
      companyName: '',
      jobLevel: 'Fundamental',
      fileName: '',
      errorRes: '',
      successRes: ''
    }
    this.onChange = this.onChange.bind(this)
    this.fileOnChange = this.fileOnChange.bind(this)
    this.saveBtn = this.saveBtn.bind(this)
  }
  componentDidMount() {
    document.title = "Job Portal | Add Experience"
  }
  saveBtn() {
    if (this.state.positionTitle.length > 0 && this.state.fromDate.length > 0 && this.state.toDate.length > 0 && this.state.companyName.length > 0 && this.state.jobLevel.length > 0 && this.state.fileName.length > 0) {
      const req1 = {
        login_user_id: LoginRow().id,
        position_title: this.state.positionTitle,
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        company_name: this.state.companyName,
        job_level: this.state.jobLevel,
        file_name: this.state.fileName,
        exp_btn: ''
      }
      APIUrl.post('/newExperience', req1).then(res => {

        if (res.data.result.success) {
          this.setState({ errorRes: '' })
          this.setState({ successRes: 'Successfully Added' })
        } else {
          this.setState({ successRes: '' })
          this.setState({ errorRes: res.data.result.failed })
        }

      })
    } else {
      this.setState({ errorRes: 'all field required' })
    }

  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  fileOnChange(e) {
    let files = e.target.files
    let reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = (e) => {
      this.setState({ fileName: e.target.result })
    }
  }
  render() {
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">

            <form>
              <div className="content-back">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Add New Experience</h1>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    {this.state.successRes.length > 0 ? <p className="alert alert-success text-center"> {this.state.successRes} </p> : ''}
                    {this.state.errorRes.length > 0 ? <p className="alert alert-danger text-center"> {this.state.errorRes} </p> : ''}
                    <label>Postition Title</label>
                    <input type="text" value={this.state.positionTitle} placeholder="Position Title" name="positionTitle" className="form-control" onChange={this.onChange} />
                    <label>From Date</label>
                    <input type="date" value={this.state.fromDate} name="fromDate" onChange={this.onChange} className="form-control" />
                    <label>To Date</label>
                    <input type="date" value={this.state.toDate} name="toDate" onChange={this.onChange} className="form-control" />
                    <label>Company Name</label>
                    <input type="text" value={this.state.companyName} onChange={this.onChange} name="companyName" placeholder="Company Name" className="form-control" />
                    <label>Job Level</label>
                    <select name="jobLevel" onChange={this.onChange} className="form-control">
                      <option value="Fundamental" >Fundamental Awareness (basic knowledge)</option>
                      <option value="Novice" >Novice (limited experience)</option>
                      <option value="Intermediate">Intermediate (practical application)</option>
                      <option value="Advanced">Advanced (applied theory)</option>
                      <option value="Expert">Expert (recognized authority)</option>
                    </select>
                    <label>Experience Certificate</label>
                    <input type="file" name="files" onChange={this.fileOnChange} className="form-control" accept=".pdf,image/png,image/jpg,image/jpeg" />
                    <br />
                    <button className="btn btn-info" id="regBtn" type="button" onClick={this.saveBtn}>Save Experience</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default AddExperience;
