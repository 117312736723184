import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

class Index extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-3"><Skeleton height={60} /></div>
                        <div className="col-md-5"><Skeleton height={60} /></div>
                        <div className="col-md-2"><Skeleton height={60} /></div>
                        <div className="col-md-2"><Skeleton height={60} /></div>
                        <div className="col-md-8"><br /><br /><Skeleton height={300} /></div>
                        <div className="col-md-4"><br /><br /><Skeleton height={300} /></div>
                        <div className="col-md-8"><br /><Skeleton height={30} /><Skeleton count={15} /></div>
                        <div className="col-md-4"><br /><Skeleton height={50} /><Skeleton count={4} circle={true} height={50} width={50} /></div>
                        <div className="col-md-4"><br /><Skeleton height={50} /></div>
                        <div className="col-md-4"><br /><Skeleton height={50} /></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;