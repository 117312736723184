import React, { Component } from 'react';
import TeamList from './TeamList';
import APIUrl from '../../APIUrl';
class TeamSection extends Component {
  constructor() {
    super();
    this.state = {
      ourTeam: [],
      index: 1
    }
  }
  componentDidMount() {
    APIUrl.get('/ourTeam').then(res => {
      this.setState({ ourTeam: res.data.result });
    })
  }
  render() {
    return (
      <section className="site-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center" data-aos="fade">
            {this.state.ourTeam.length > 0 ? 
                <h2 className="section-title mb-3">Our Team</h2>: ''
              }
            </div>
          </div>
          {this.state.ourTeam.map((ot, index) => <TeamList key={ot.id} ourTeam={ot} class_name={(index + 1) % 2} />)}


        </div>
      </section>

    );
  }
}

export default TeamSection;