import React from 'react'
import { Link } from 'react-router-dom'

function Pagination({ postsPerPage, totalPosts, paginate }) {
    const pageNumber = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumber.push(i);
    }
    return (
        <div>
            {pageNumber.map(number =>
                <Link onClick={() => paginate(number)} to="#" key={number} >{number}</Link>
            )}
        </div>
    )
}


export default Pagination
