import React from 'react';
import { OurTeamUrl } from '../../AllProgramming';
import './css/TeamList.css';
function TeamList(props) {
  let new_class_name = '';
  if (props.class_name === 0) {
    new_class_name = "col-md-6 order-md-2";
  } else {
    new_class_name = "col-md-6";
  }
  let teamProfile = OurTeamUrl() + props.ourTeam.profile_name;
  return (
    <div className="row align-items-center block__69944">
      <div className={new_class_name}>
        <img src={teamProfile} alt="" className="img-fluid mb-4 rounded our-team-profile" />
      </div>
      <div className="col-md-6">
        <h3>{props.ourTeam.name}</h3>
        <p className="text-muted">{props.ourTeam.position}</p>
        <p className="text-justify">{props.ourTeam.detail}</p>
        <div className="social mt-4">
          {props.ourTeam.fb_link.length > 0 ? <a href={props.ourTeam.fb_link}><span className="icon-facebook"></span></a> : ''}
          {props.ourTeam.tw_link.length > 0 ? <a href={props.ourTeam.tw_link}><span className="icon-twitter"></span></a> : ''}
          {props.ourTeam.pt_link.length > 0 ? <a href={props.ourTeam.pt_link}><span className="icon-pinterest"></span></a> : ''}
          {props.ourTeam.ld_link.length > 0 ? <a href={props.ourTeam.ld_link}><span className="icon-linkedin"></span></a> : ''}
        </div>
      </div>
    </div>
  );
}

export default TeamList;