import React from 'react';

import './jobWeb/assets/css/custom-bs.css';
import './jobWeb/assets/css/jquery.fancybox.min.css';
import './jobWeb/assets/css/bootstrap-select.min.css';
import './jobWeb/assets/fonts/icomoon/style.css';
import './jobWeb/assets/fonts/line-icons/style.css';
import './jobWeb/assets/css/animate.min.css';
import './jobWeb/assets/css/style.css';

import CustomRoutes from './CustomRoutes';

function App() {
  return (
        <CustomRoutes />
  );
} 

export default App;
