import React from 'react';

import Header from '../components/Header';
import FooterSection from '../components/FooterSection';
import Home2Section from '../components/Home2Section';
import APIUrl from '../../APIUrl';

class TearmAndCondition extends React.Component {
    constructor() {
        super();
        this.state = {
            aboutPageDetail: ''
        }
    }
    componentDidMount() {
        const page_req = {
            page_name: 'term'
        }
        APIUrl.post('/samePages', page_req).then(res => {
            this.setState({ aboutPageDetail: res.data.result.content_detail });
        });
        document.title = "Job Portal | Term & Condition";
    }
    render() {
        let text1 = this.state.aboutPageDetail;
        function htmlMove() { return { __html: text1 } };
        return (
            <div className="site-wrap">
                <Header />
                <Home2Section page_name="Term & Condation" detail_name="Job Portal Term & Condition" />
                <section className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-5">
                                <div dangerouslySetInnerHTML={htmlMove()} />
                            </div>
                        </div>
                    </div>
                </section>
                <FooterSection />
            </div>
        );
    }
}

export default TearmAndCondition;