import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/Profile.css';



class Profile extends Component {
  constructor() {
    super();
    this.state = {
      fname: '',
      lname: '',
      email: '',
      gender: '',
      mobile: '',
      cnic: '',
      age: '',
      country: '',
      city: '',
      countryId: 0,
      cityId: 0,
      profileImg: '',
      fileName: '',
      countryList: [],
      cityList: [],
      successRes: ''
    }
    this.fetchProfile = this.fetchProfile.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchCountry = this.fetchCountry.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.updateBtn = this.updateBtn.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }
  componentDidMount() {
    this.fetchProfile();
    this.fetchCountry();
    document.title="Job Portal | User Profile"
  }
  fetchProfile() {
    const req_m = {
      user_id: LoginRow().id
    }
    APIUrl.post('/viewUser', req_m).then(res => {
      this.setState({
        fname: res.data.result.fname,
        lname: res.data.result.lname,
        email: res.data.result.email,
        gender: res.data.result.gender,
        mobile: res.data.result.mobile,
        cnic: res.data.result.cnic,
        age: res.data.result.age,
        country: res.data.result.country,
        countryId: res.data.result.country_id,
        city: res.data.result.city,
        cityId: res.data.result.city_id,
        profileImg: res.data.result.profileImg,
      })
    })
  }
  fetchCountry() {
    APIUrl.get('/viewCountry').then(res => {
      this.setState({ countryList: res.data.result });
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onChangeCountry(e) {
    this.setState({ countryId: e.target.value });
    const req_mm = '/viewCityACountry/' + e.target.value;
    APIUrl.get(req_mm).then(res => {
      this.setState({ cityList: res.data.result });
    })
  }
  updateBtn() {
    const req1 = {
      fname: this.state.fname,
      lname: this.state.lname,
      id: LoginRow().id,
      gender: this.state.gender,
      age: this.state.age,
      mobile: this.state.mobile,
      cnic: this.state.cnic,
      city_id: this.state.cityId,
      country_id: this.state.countryId,
      file_name: this.state.fileName
    }
    APIUrl.post('/updateUserPrv', req1).then(res1 => {
      console.log(res1.data);
      this.setState({ successRes: 'Profile Successfully Updated' })
    })
  }
  onFileChange(e) {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      this.setState({ fileName: e.target.result });
    }
  }
  render() {
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">


            <form>
              <div className="content-back">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Edit Profile</h1>
                  </div>
                  <div className="col-md-12">
                    {this.state.successRes.length > 0 ? <p className="alert alert-success text-center"> {this.state.successRes} </p> : ''}
                  </div>
                  <div className="col-md-4">
                    <label>First Name</label>
                    <input type='text' name='fname' placeholder="First Name" onChange={this.onChange} value={this.state.fname} className="form-control" />
                    <label>Mobile</label>
                    <input type='text' name='mobile' placeholder="Mobile" onChange={this.onChange} value={this.state.mobile} className="form-control" />
                  </div>
                  <div className="col-md-4">
                    <label>Last Name</label>
                    <input type='text' name='lname' placeholder="Last Name" onChange={this.onChange} value={this.state.lname} className="form-control" />
                    <label>Age</label>
                    <input type='int' maxLength="2" minLength="1" name='age' placeholder="Age" onChange={this.onChange} value={this.state.age} className="form-control" />
                  </div>
                  <div className="col-md-4">
                    <label>Email</label>
                    <input type='text' readOnly name='email' placeholder="Email" onChange={this.onChange} value={this.state.email} className="form-control" />
                    <label>CNIC</label>
                    <input type='int' maxLength="15" name='cnic' placeholder="CNIC" onChange={this.onChange} value={this.state.cnic} className="form-control" />
                  </div>
                  <div className="col-md-3">
                    <label>Profile Image</label>
                    <input type="file" accept="image/png,image/jpg,image/jpeg" name="file" onChange={this.onFileChange} className="form-control" />
                  </div>
                  <div className="col-md-3">
                    <label>Gender</label>
                    <select onChange={this.onChange} name="gender" className="form-control">
                      <option value={this.state.gender}> {this.state.gender} </option>
                      <option value="male"> Male </option>
                      <option value="female"> Female </option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>Country</label>
                    <select onChange={this.onChangeCountry} name="countryId" className="form-control">
                      <option value={this.state.countryId}> {this.state.country} </option>
                      {this.state.countryList.map(cL =>
                        <option key={cL.country_id} value={cL.country_id}> {cL.country_name} </option>
                      )}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>City</label> :
            <b> {this.state.city} </b>
                    <select onChange={this.onChange} name="cityId" className="form-control">
                      {this.state.cityList.map(cL =>
                        <option key={cL.city_id} value={cL.city_id}> {cL.city_name} </option>
                      )}
                    </select>
                  </div>

                  <div className="col-md-4">
                    <br />
                    <button type="button" id="save_btn" className="btn btn-info" onClick={this.updateBtn}>Update Profile</button>
                  </div>

                </div>
              </div>
            </form>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default Profile;
