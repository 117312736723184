import React, { Component } from 'react';
import APIUrl from '../../APIUrl';

class AboutSection extends Component {
   constructor() {
      super();
      this.state = {
         aboutPageDetail: ''
      }
   }
   componentDidMount() {
      const page_req = {
         page_name: 'about',
         web_type: 2
      }
      APIUrl.post('/samePages', page_req).then(res => {
         this.setState({ aboutPageDetail: res.data.result.content_detail });
      })
   }
   render() {
      let text1 = this.state.aboutPageDetail;
      function htmlMove() { return { __html: text1 } };
      return (
         <div>
            <section className="site-section pb-0">
               <div className="container">
                  <div dangerouslySetInnerHTML={htmlMove()} />
               </div>
            </section>
         </div>
      );
   }
}
export default AboutSection;