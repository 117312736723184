import React from 'react';
import JobCity from './JobCity';
import './css/JobCountry.css';

export default function JobCountry(props) {
    return (
        <div>
            <strong className="text-black">Location:</strong>
            <br />
            <div>{props.country.map(cty => <span key={cty.country_name}><b className="country_class">{cty.country_name}</b> <JobCity cityName={cty.cities} /> </span>)}</div>
        </div>
    )
}