import React from 'react'
import { EventImgUrl } from '../../AllProgramming'
import './css/jobList.css'


function JobList(props) {
  let get_event_img = EventImgUrl() + props.img_name
  let event_url = "/Event/EventView/" + props.url
  return (
    
        <div className="grid">
					<figure className="effect-romeo">
						<img src={get_event_img} alt="img17"/>
						<figcaption>
							<h2> {props.start_date} </h2>
							<p>{props.title}</p>
							<a href={event_url}>View more</a>
						</figcaption>			
					</figure>
				</div>
    
      // <div className="block">
      //   <img src={get_event_img} alt={props.title} className="event-logo" />
      //   <div className="top-banner"></div>
      //   <h3 className="centered"> {props.title} </h3> 
      // </div>
    
    // <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center">
    //   <a href={job_url}> </a>
    //   <div className="job-listing-logo">
    //     <img src={get_company_logo} alt="Company Logo" className="job-logo" />
    //   </div>

    //   <div className="job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4">
    //     <div className="job-listing-position custom-width w-50 mb-3 mb-sm-0">
    //       <h2>{props.title}</h2>
    //       <strong>{props.category}</strong>
    //     </div>
    //     <div className="job-listing-location mb-3 mb-sm-0 custom-width w-25">
    //       <span className="icon-room"></span> {props.country.map(ct => <span key={ct.country_name}>{ct.country_name}, </span>)}
    //     </div>
    //     <div className="job-listing-meta">
    //       <span className={class_name}>{props.type}</span>
    //     </div>
    //   </div>
    // </li>
  );
}

export default JobList;