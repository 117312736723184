import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../../APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/ViewExperience.css';


class ViewExperience extends Component {
    constructor() {
        super();
        this.state = {
            transactionList: [],
        }
        this.fetchRecord = this.fetchRecord.bind(this);
    }
    componentDidMount() {
        document.title = "Job Portal | View Transaction Detail"
        this.fetchRecord();
    }
    fetchRecord() {
        const req_m = {
            user_id: LoginRow().id
        }
        APIUrl.post('/viewUserTransaction', req_m).then(res => {
            this.setState({ transactionList: res.data.result });
        })
    }
    render() {
        return (
            <div className="c-app c-default-layout">
                <TheSidebar />
                <div className="c-wrapper">
                    <TheHeader />
                    <div className="c-body">

                        <div className="content-back">
                            <h1> Transaction List </h1>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Plan Title</th>
                                        <th>Price</th>
                                        <th>Total Months</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Time</th>
                                        <th>Remaining Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.transactionList.map((jl, index) =>
                                        <tr key={jl.id}>
                                            <td> {index + 1} </td>
                                            <td> {jl.plan_name} </td>
                                            <td>$ {jl.price}</td>
                                            <td> {jl.months === "1" ? jl.months + " Month" : jl.months + " Months"} </td>
                                            <td> {jl.from_date} </td>
                                            <td> <b>{jl.to_date}</b> </td>
                                            <td> {jl.time} </td>
                                            <td> {jl.remaining_days} </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <TheFooter />
                </div>
            </div>
        )
    }
}

export default ViewExperience;
