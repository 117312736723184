import React from 'react';
import './css/Home2Section.css';
function Home2Section(props) {
  return (
    <section className="section-hero overlay inner-page bg-image home2-section" id="home-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-white">{props.page_name}</h1>
            <div className="custom-breadcrumbs">
              <a href="/">Home</a> <span className="mx-2 slash">/</span>
              <span className="text-white"><strong> {props.detail_name} </strong></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home2Section;