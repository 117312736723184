import React, { Component } from 'react';
import { LoginRow } from '../../../AllProgramming';
import APIUrl from '../../..//APIUrl';
import { TheSidebar, TheFooter, TheHeader } from '../containers/index'
import './css/AllJobApplicationList.css';

class StatusJobApplicationList extends Component {
  constructor() {
    super();
    this.state = {
      jobList: [],
      statusName: '',
      lastId: 0
    }
    this.fetchdata = this.fetchdata.bind(this);
    this.cancelApplyJob=this.cancelApplyJob.bind(this)
  }

  fetchdata() {
    const req_m = {
      user_id: LoginRow().id,
      job_application_status_id: this.props.match.params.id
    }
    APIUrl.post('/userStatusJobApplication', req_m).then(res => {
      this.setState({ jobList: res.data.result });
    });
    let st_id = '/singleApplicationStatus/' + this.props.match.params.id;
    APIUrl.get(st_id).then(res => {
      if (res.data.result.length === 0) {
        this.setState({ statusName: "Sorry invalid request" });
        document.title ="Job Portal | sorry invalid request Job Application List"
      } else {
        this.setState({ statusName: res.data.result[0].name });
        document.title ="Job Portal | "+ res.data.result[0].name + " Job Application List"
      }
      
    });
  }


  componentDidMount() {
    this.fetchdata();
  }

  cancelApplyJob(job_id){
    const req_data = {
      user_id: LoginRow().id,
      job_id: job_id
    }
    APIUrl.post('/jobApplication', req_data).then(res => {
      this.fetchdata()
    })
  }

  render() {
    let class_name = "alert alert-"
    let url_job_detail="../../JobView/"
    return (
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">

            <div className="content-back">
              <h1> {this.state.statusName} Job Application </h1>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Category</th>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Total Seat</th>
                    <th>Salary</th>
                    <th>Gender</th>
                    <th>End Date</th>
                    <th>Apply Date</th>
                    <th>Status</th>
                    {this.props.match.params.id === '1' ? 
                      <th>Action </th>
                      : ''}
                  </tr>
                </thead>
                <tbody>
                  {this.state.jobList.map((jl, index) =>
                    <tr key={jl.application_id}>
                      <td> {index + 1} </td>
                      <td> {jl.category} </td>
                      <td> {jl.type} </td>
                      <td> <a href={url_job_detail+jl.url} >{jl.title}</a> </td>
                      <td> {jl.total_seat} </td>
                      <td> {jl.salary} </td>
                      <td> {jl.gender_type} </td>
                      <td> {jl.end_date} </td>
                      <td> {jl.application_date} </td>
                      <td className={class_name + jl.classNameGet}><b> {jl.application_status} </b> </td>
                      {this.props.match.params.id === '1' ? 
                      <td> <button onClick={() => this.cancelApplyJob(jl.job_id)} className="btn btn-danger">Cancel Apply</button> </td>
                      : ''}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <TheFooter />
        </div>
      </div>
    )
  }
}

export default StatusJobApplicationList;
