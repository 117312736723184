
function ForPath() {
    // var mainURL='https://clients.timetechsol.com/api/jobportal/upload'
    // var mainURL='https://jobportal.timetechsol.com/api/upload'
    // var mainURL='https://jobadmin.timetechsol.com/admin/upload'
    var mainURL = 'https://jobs.aaafm.org/admin/upload'
    // var mainURL='http://localhost:8080/react_jobportal_api/upload'
    return mainURL
}

function ForAPI() {
    // var mainURL= 'https://clients.timetechsol.com/api/jobportal/'
    // var mainURL= 'https://jobportal.timetechsol.com/api/'
    // var mainURL= 'https://jobadmin.timetechsol.com/admin/api/'
    var mainURL= 'https://jobs.aaafm.org/admin/api/'
    // var mainURL='http://localhost:8080/react_jobportal_api/api/'
    return mainURL
}

export {
    ForPath,
    ForAPI
}