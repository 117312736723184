import React, { Component } from 'react';
import './css/HomeSection.css';
import SearchForm from './SearchForm';
class HomeSection extends Component {

    render() {

        return (
            <section className="home-section section-hero overlay bg-image" id="home-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-12">

                            <div>

                                <SearchForm pKeyword={this.props.pKeyword} pCategory={this.props.pCategory} pType={this.props.pType} pCity={this.props.pCity} />
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#next" className="scroll-button smoothscroll">
                    <span className=" icon-keyboard_arrow_down"></span>
                </a>
            </section>
        );
    }
}

export default HomeSection;