import React from 'react'

import Header from '../components/Header'
import SiteSection from '../components/SiteSection'
import FooterSection from '../components/FooterSection'
import Home3Section from '../components/Home3Section'

class JobSearch extends React.Component {
    componentDidMount() {
        let TCategory;
        let TType;
        let TCity;
        if (this.props.match.params.category) {
            TCategory = " - " + this.props.match.params.category
        } else {
            TCategory = ""
        }
        if (this.props.match.params.type) {
            TType = " - " + this.props.match.params.type
        } else {
            TType = ""
        }
        if (this.props.match.params.city) {
            TCity = " - " + this.props.match.params.city
        } else {
            TCity = ""
        }
        document.title = this.props.match.params.keyword.replace(/-/g, " ") + TCategory + TType + TCity
    }
    render() {

        let pCategory;
        let pType;
        let pCity;
        let ACategory
        let AType;
        let ACity;
        if (this.props.match.params.category) {
            pCategory = this.props.match.params.category
            ACategory = this.props.match.params.category
        } else {
            pCategory = ""
            ACategory = "none"
        }
        if (this.props.match.params.type) {
            pType = this.props.match.params.type
            AType = this.props.match.params.type
        } else {
            pType = ""
            AType = "none"
        }
        if (this.props.match.params.city) {
            pCity = this.props.match.params.city
            ACity = this.props.match.params.city
        } else {
            pCity = ""
            ACity = "none"
        }
        let calling_api = "/eventSearch/" + this.props.match.params.keyword + "/" + ACategory + "/" + AType + "/" + ACity;

        return (
            <div className="site-wrap">
                <Header />
                <Home3Section pKeyword={this.props.match.params.keyword} pCategory={pCategory} pType={pType} pCity={pCity} />
                <SiteSection callAPI={calling_api} />
                <FooterSection />
            </div>
        );
    }
}

export default JobSearch;