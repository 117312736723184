import React, { useState, useEffect } from 'react';
import './css/SiteSection.css';
import JobList from './JobList';
import APIUrl from '../../APIUrl';
import Pagination from './Pagination';
import SSiteSection from '../skeleton/SSiteSection';

function SiteSection(props) {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(100);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fetchPosts = async () => {
            const res = await APIUrl.get(props.callAPI);
            setPosts(res.data.result);
            setLoading(false);
        };
        fetchPosts();

    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    let lastNumber = 0;
    if (indexOfLastPost < posts.length) {
        lastNumber = indexOfLastPost;
    } else {
        lastNumber = posts.length;
    }
    if (loading) {
        return (<SSiteSection />)
    }
    return (

        <section className="site-section">
            <div className="container">
                <div className="row mb-5 justify-content-center">
                    <div className="col-md-7 text-center">
                        <h2 className="section-title mb-2">{posts.length} Latest Job Listed</h2>
                    </div>
                </div>
                <ul className="job-listings mb-5">
                    {currentPosts.map(jobView =>
                        <div key={jobView.job_id}>
                            <JobList
                                url={jobView.job_url}
                                id={jobView.job_id}
                                title={jobView.job_title}
                                category={jobView.category_name}
                                type={jobView.type_name}
                                country={jobView.country_city.countries}
                                company_logo={jobView.company_logo}
                                class_name={jobView.class_name}
                            />
                        </div>
                    )}
                </ul>
                <div className="row pagination-wrap">
                    <div className="col-md-6 text-center text-md-left mb-4 mb-md-0">
                        <span>Showing {posts.length === 0 ? posts.length : indexOfFirstPost + 1} -  {lastNumber} Of {posts.length} Jobs</span>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                        <div className="custom-pagination ml-auto">
                            <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default SiteSection;