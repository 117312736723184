import React from 'react';

import Header from '../components/Header';
import FooterSection from '../components/FooterSection';
import Home2Section from '../components/Home2Section';
import AboutSection from '../components/AboutSection';
import TeamSection from '../components/TeamSection';

class About extends React.Component {
    componentDidMount() {
        document.title = "Job Portal | About Us";
    }
    render() {
        return (
            <div className="site-wrap">
                <Header />
                <Home2Section page_name="About Us" detail_name="Event Management About" />
                <AboutSection />
                <TeamSection />
                <FooterSection />
            </div>
        );
    }
}

export default About;